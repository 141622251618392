import LandPage from './components/LandPage';

function App() {
  return (
    <div>
      <LandPage />
    </div>
  );
}

export default App;
